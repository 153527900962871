<template>
  <div class="hotel_details_area">
    <div class="hotel_details_heading">
      <h3>
        <span class="icon"><img src="/assets/img/after-booking-icon7.png" alt="after-booking-icon" /></span>
        {{ $t("booking.hotel-details") }}
      </h3>
    </div>
    <div class="hotel_details_body" v-if="device == 'desktop'">
      <div class="d-flex justify-content-between">
        <div class="hotel_details_price_box">
          <h5>{{ $t("booking.included-price") }}</h5>
          <ul v-for="(room, index) in bookedHotel.rooms" :key="index" class="p-1 mb-0 border-bottom" :class="{'border-top':index === 0}">
            <li>
              <i class="fa fa-check"></i>{{ $t("product-page.basis") }} :
              {{ $t(`basis-name.${room.basisCode.toLowerCase().trim()}`) }}
            </li>
            <li>
              <i class="fa fa-check"></i>{{ $t("product-page.room-class") }} :
              {{ room.roomClassName }}
            </li>
            <li v-if="room.roomOccupancyCode">
              <i class="fa fa-check"></i>{{ $t("booking.occupancy") }} :
              {{ $t(`room-type-list.${room.roomOccupString}`) || room.roomOccupancyCode }}
            </li>
          </ul>
          <h3 :class='bookedHotel.hotelPrice != bookedHotel.price ? "dangerPrice" : ""'>₪{{ bookedHotel.hotelPrice }}</h3>
          <!--<button class="btn-reserve">reserve</button>-->
        </div>
        <div class="hotel_details_div">
          <div class="w-100">
            <div class="d-flex ">
              <div class="hotel_details_contain_box col-lg-6">
                <div>
                  <h3>{{ bookedHotel.hotelName }}</h3>
                  <h5>
                    <i class="zmdi zmdi-calendar"></i><strong>{{ $t("booking.dates") }}:</strong> {{ bookedHotel.period }}
                  </h5>
                  <room-confirm
                    v-for="(room, index) in bookedHotel.rooms"
                    :key="index"
                    :room="room"
                  />
                </div>
              </div>

              <div class="hotel_details_image_box col-lg-6">
                <slider :slides="bookedHotel.imageList" :parent="'booking'"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hotel_details_divmobail" v-else>
      <div class="hotel_details_image_box">
        <slider :slides="bookedHotel.imageList" :parent="'booking'"/>
      </div>
      <div class="hotel_details_contain_box">
        <div>
          <h3>{{ bookedHotel.hotelName }}</h3>
          <h5>
            <i class="zmdi zmdi-calendar"></i><strong>{{ $t("booking.dates") }}:</strong>
            {{ bookedHotel.period }}
          </h5>
          <room-confirm
            v-for="(room, index) in bookedHotel.rooms"
            :key="index"
            :room="room"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    RoomConfirm: () => import('@/components/booking/atoms/RoomConfirm'),
    Slider: () => import('@/components/productPage/productSlider/ProductSliderTheme0'),
  },
  props: {
    bookedHotel: {
      type: Object,
      default: () => ({
        hotelPrice: 0,
        hotelName: '',
        grade: 0,
        period: '',
        basisCode: '',
        supplements: [],
        rooms: [],
        imageList: [],
        translations: null,
      }),
    },
  },
  data() {
    return {
      basis: this.bookedHotel.rooms[0].basisCode,
      roomClassName: this.bookedHotel.rooms[0].roomClassName,
      occupancy: this.bookedHotel.rooms[0].roomOccupancyCode,
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
    }),
  },
  watch: {
    // lang: 'updateLabelWithLang',
  },
  created() {
  },
  mounted() {
    // console.log(this.bookedHotel);
  },
};
</script>

<style>
  .dangerPrice{
    color:red;
  }
</style>

<style scoped>
.hotel_details_area .hotel_details_heading {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    0deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(39, 136, 210, 1) 0%,
    rgba(1, 98, 172, 1) 100%
  );
  padding: 15px 20px;
}
.hotel_details_area .hotel_details_heading h3 {
  margin: 0 0;
  padding: 0 0;
  color: #fff;
  text-transform: uppercase;
  font-size: 24px;
}

.hotel_details_area .hotel_details_body {
  padding: 35px 20px;
  border: 1px solid #949494;
  border-top: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 25px;
}
.hotel_details_body .hotel_details_price_box {
  width: 25%;
  padding: 20px 20px;
  border: 1px solid #949494;
  background: #f5f5f5;
  margin-bottom: 20px;
}
.hotel_details_body .hotel_details_div {
  width: 75%;
  box-sizing: border-box;
  border: 1px solid #949494;
  border-right: 0px;
  padding: 20px 20px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-bottom: 20px;
}

.hotel_details_contain_box {
  width: 60%;
  box-sizing: border-box;
  padding: 0 0;
}

.hotel_details_image_box {
  width: 40%;
  box-sizing: border-box;
  padding: 0 0;
}
.hotel_details_body .hotel_details_price_box ul {
  list-style: none;
}
.hotel_details_body .hotel_details_price_box ul li {
  position: relative;
  padding-right: 24px;
  text-transform: capitalize;
  margin-bottom: 0;
  font-size: 14px;
}
.hotel_details_body .hotel_details_price_box ul li i {
  position: absolute;
  top: 0;
  right: 0;
  color: #2485cf;
  font-size: 15px;
  font-weight: 600;
  margin-top: 5px;
}

.hotel_details_price_box h3 {
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
}

.hotel_details_contain_box h3 {
  font-size: 24px;
  font-weight: 600;
  color: #000;
  text-transform: capitalize;
  margin-bottom: 0;
}

.hotel_details_contain_box output {
  border: none;
  padding-top: 0px;
  margin-top: 0px;
}

.hotel_details_contain_box .b-rating .b-rating-star {
  display: inline-block;
  font: normal normal normal 14px/1 "Material-Design-Iconic-Font";
  font-size: 20px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 0px;
}

@media (max-width: 479px) {
  .hotel_details_area .hotel_details_heading h3 {
    font-size: 20px;
  }
  .hotel_details_divmobail {
    margin-top: 10px;
  }
  .hotel_details_divmobail {
    display: block;
    box-sizing: border-box;
    border: 1px solid #d2d2d2;
    box-sizing: border-box;
    padding: 8px;
  }
  .hotel_details_image_box {
    width: 100%;
  }
  .hotel_details_contain_box {
    width: 100%;
  }
  .hotel_details_contain_box h3 {
    font-size: 20px;
  }
  .hotel_details_contain_box h5 {
    font-size: 15px;
  }
  .hotel_details_price_box h5 {
    font-size: 20px;
  }
  .hotel_details_price_box li {
    font-size: 15px;
  }

}
</style>
